<template>
  <div>
    <TheNavbar/>
    <div class="container mt-4">
      <!-- ACCOUNT TITLE -->
      <div class="row mb-3">
        <div class="col-md-12 animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Account</h1>
        </div>
      </div>

      <!-- ACCOUNT DATA -->
      <div class="row mb-3">

        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-header mt-2">
              <p class="overline text-muted">Basic Information</p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-borderless table-hover">
                  <tbody>
                  <tr>
                    <th scope="row" class="w-50">UUID</th>
                    <td>{{ currentUser.uid || "Loading..." }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="w-50">Name</th>
                    <td>{{ currentUser.displayName || "Loading..." }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{{ currentUser.email || "Loading..." }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-header mt-2">
              <p class="overline text-muted">Linked account</p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-borderless table-hover">
                  <tbody>
                  <tr>
                    <th scope="row" class="w-50 align-middle">Sign in with</th>
                    <td v-if="currentUser.providerData[0].providerId === 'google.com'">
                      <svg width='24' height='24' class='me-2'>
                        <g fill='none' fill-rule='evenodd'>
                          <path
                              d='M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z'
                              fill='#4285F4'></path>
                          <path
                              d='M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z'
                              fill='#34A853'></path>
                          <path
                              d='M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z'
                              fill='#FBBC05'></path>
                          <path
                              d='M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z'
                              fill='#EA4335'></path>
                        </g>
                      </svg>
                      <span>{{ 'Google' || "Loading..." }}</span>
                    </td>
                    <td v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class='me-2'>
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                            d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
                            fill="rgba(23,120,242,1)"/>
                      </svg>
                      <span>{{ 'Facebook' || "Loading..." }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card card-outline">
            <div class="card-header mt-2">
              <p class="overline text-muted">Subscription details</p>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-hover">
                <tbody>
                <tr>
                  <th class="align-middle">Subscription status</th>
                  <td>
                    <div v-if="membership?.status === 2 && membership?.isTrialing" class="badge badge-info px-3">
                      <i class="material-icons-outlined me-1">&#xe86c;</i>
                      Free trial
                    </div>
                    <div v-else-if="membership?.status === 2"
                         class="badge badge-success px-3">
                      <i class="material-icons-outlined me-1">&#xe876;</i>
                      Active
                    </div>
                    <div v-else-if="membership?.status === 3 || membership?.status === 4"
                         class="badge badge-warning px-3">
                      <i class="material-icons-outlined me-1">&#xe644;</i>
                      Not active
                    </div>
                  </td>
                </tr>

                <tr v-if="membership?.status === 2 && membership?.isTrialing">
                  <th scope="row" class="w-50 align-middle">Trial renews on</th>
                  <td>
                    {{ new Date(membership?.currentPeriodEnd * 1000).toDateString() }}

                    <span class="text-muted" v-if="membership?.cancelAtPeriodEnd">
                      (Subscription will cancel when period ends)
                    </span>
                  </td>
                </tr>
                <tr v-else-if="membership?.status === 2">
                  <th scope="row" class="w-50 align-middle">Renewal date</th>
                  <td>
                    {{ new Date(membership?.currentPeriodEnd * 1000).toDateString() }}

                    <span class="text-muted" v-if="membership?.cancelAtPeriodEnd">
                      (Subscription will cancel when period ends)
                    </span>
                  </td>
                </tr>

                <tr v-if="membership?.endedAt">
                  <th scope="row" class="w-50 align-middle">Period ended at</th>
                  <td>
                    {{ new Date(membership?.endedAt * 1000).toDateString() }}
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import {firebaseAuth, getMembership} from "@/firebase";

export default {
  components: {TheNavbar},
  data() {
    return {
      currentUser: firebaseAuth.currentUser,
      restaurantLogo: null,
      membership: null,
    }
  },
  mounted() {
    this.fetchMembership()
  },
  methods: {
    async fetchMembership() {
      this.membership = await getMembership(this.currentUser?.uid)
    }
  }
}
</script>
